<template lang="pug">
.menu-options
  .options(@click="toggleMenu")
    svg-icon(:path="mdiDotsVertical", type="mdi", :size="24")
  XyzTransition(xyz="fade down duration-3")
    .menu(v-if="showMenu")
      ul
        li(@click="toggleReplaceReceipt(true)") Cambiar receta
        li(@click="toggleExcludeReceipt(true)") Excluir receta
modalExcludeReceipt(
  :showModal="showExcludeReceipt",
  :menuId="menuId",
  :receiptAdaptedId="receiptAdaptedId",
  v-on:closeModal="toggleExcludeReceipt(false)"
)
modalReplaceReceipt(
  :showModal="showReplaceReceipt",
  :menuId="menuId",
  :receiptAdaptedId="receiptAdaptedId",
  v-on:closeModal="toggleReplaceReceipt(false)"
)
</template>
<script>
import { mdiDotsVertical } from "@mdi/js";
import { ref, computed, toRefs, inject } from "vue";
import { useStore } from "vuex";

import modalExcludeReceipt from "./modal-exclude-receipt.vue";
import modalReplaceReceipt from "./modal-replace-receipt.vue";

export default {
  name: "menu.options",
  data: () => ({
    mdiDotsVertical,
  }),
  components: {
    modalExcludeReceipt,
    modalReplaceReceipt,
  },
  setup() {
    const store = useStore();

    const menuId = computed(() => store.getters["menuStore/getMenuId"]);

    const receiptAdaptedId = computed(
      () => store.getters["menuReceiptStore/getAdaptedReceiptId"]
    );

    const showMenu = ref(false);
    const toggleMenu = () => (showMenu.value = !showMenu.value);

    const showExcludeReceipt = ref(false);
    const toggleExcludeReceipt = (show) => {
      showExcludeReceipt.value = show;
      if (show == false) showMenu.value = false;
    };

    const showReplaceReceipt = ref(false);
    const toggleReplaceReceipt = (show) => {
      showReplaceReceipt.value = show;
      if (show == false) showMenu.value = false;
    };

    return {
      menuId,
      receiptAdaptedId,
      showMenu,
      toggleMenu,
      showExcludeReceipt,
      toggleExcludeReceipt,
      showReplaceReceipt,
      toggleReplaceReceipt,
    };
  },
};
</script>
<style lang="scss" scoped>
.menu-options {
  @apply ml-2 relative;
  .options {
    @apply py-2 px-2 bg-gray-100 rounded-lg cursor-pointer;

    &:hover {
      @apply bg-gray-200;
    }
  }

  .menu {
    @apply w-56 bg-white absolute right-0 z-50 mt-2 rounded-md shadow-md border;
    ul li {
      @apply py-2 px-4 text-sm font-medium text-center cursor-pointer;
      & + li {
        @apply border-t;
      }

      &:hover {
        @apply bg-gray-50;
      }
    }
  }
}
</style>