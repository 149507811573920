import { setDataAnalysis, getDataAnalysis } from '@/app/repository_storage/analysis.storage';

import { KetoCalculator, ActivityFrecuency } from '@/app/keto.calculator';

const dataAnalysisStorage = getDataAnalysis();
const ketoCalculator = new KetoCalculator();

const state = () => ({
  gender: dataAnalysisStorage?.gender ?? null,
  age: dataAnalysisStorage?.age ?? null,
  metric: dataAnalysisStorage?.metric ?? 'metric', // [metric, imperial]
  height: dataAnalysisStorage?.height ?? null,
  feets: dataAnalysisStorage?.feets ?? null,
  inches: dataAnalysisStorage?.inches ?? null,
  weight: dataAnalysisStorage?.weight ?? null,
  weightObjective: dataAnalysisStorage?.weightObjective ?? null,
  activity: dataAnalysisStorage?.activity ?? null // [sedentary, light, moderate, active, extreme].
});

const getters = {
  getDataWizard: state => {
    const numActivity = ActivityFrecuency[state.activity];
    return {
      ...state,
      activity: numActivity
    };
  },
  getGender: state => state.gender,
  getActivity: state => state.activity,
  getMetric: state => state.metric,
  getBMR: state => {
    if (state.metric == 'metric') {
      return ketoCalculator.calculateBmr(state.gender, state.age, state.height, state.weight);
    } else {
      return ketoCalculator.calculateBmrImperial(state.gender, state.age, state.feets, state.inches, state.weight);
    }
  },
  getBMI: state => {
    if (state.metric == 'metric') {
      return ketoCalculator.calculateBmi(state.height, state.weight);
    } else {
      return ketoCalculator.calculateBmiImperial(state.feets, state.inches, state.weight);
    }
  },
  getIdealBodyWeight: state => {
    return ketoCalculator.calculateIdealWeigth(state.gender, state.height);
  },
  getCalorieNeeds: state => {
    if (state.metric == 'metric') {
      return ketoCalculator.calculateCalories(state.gender, state.age, state.height, state.weight, state.activity);
    } else {
      return ketoCalculator.calculateCaloriesImperial(state.gender, state.age, state.feets, state.inches, state.weight, state.activity);
    }
  },
  getMacros: (state, getters) => {
    return ketoCalculator.calculateMacros(getters.getCalorieNeeds.heavyWeightLoss);
  },
  weightAfterMonth: state => {
    return ketoCalculator.calculateWeightAfterMonth(state.weight, state.weightObjective);
  },
  getBMICategory: (state, getters) => {
    return ketoCalculator.bmiCategory(getters.getBMI);
  },

  getMetabolicAge: (state, getters) => {
    return ketoCalculator.calculateMetabolicAge(getters.getBMICategory, state.age);
  },

  getWaterNeed: state => {
    if (state.metric == 'metric') {
      return ketoCalculator.calculateWater(state.weight);
    } else {
      return ketoCalculator.calculateWaterImperial(state.weight);
    }
  }
};

const actions = {
  setGender({ commit, state }, payload) {
    if (payload == 'female') commit('setGender', 'female');
    if (payload == 'male') commit('setGender', 'male');
    setDataAnalysis(state);
  },
  setActivity({ commit, state }, payload) {
    commit('setActivity', payload);
    setDataAnalysis(state);
  },
  setMetric({ commit, state }, payload) {
    commit('setMetric', payload);
    setDataAnalysis(state);
  },
  setMeasurement({ commit, state }, payload) {
    commit('setAge', payload.age);
    commit('setWeight', payload.weight);
    commit('setWeightObjective', payload.objectiveWeight);
    commit('setHeight', payload.height);
    commit('setFeets', payload.feets);
    commit('setInches', payload.inches);
    setDataAnalysis(state);
  }
};

const mutations = {
  setGender(state, gender) {
    state.gender = gender;
  },
  setActivity(state, activity) {
    state.activity = activity;
  },
  setMetric(state, metric) {
    state.metric = metric;
  },
  setAge(state, age) {
    state.age = age;
  },
  setWeight(state, weight) {
    state.weight = weight;
  },
  setWeightObjective(state, weightObjective) {
    state.weightObjective = weightObjective;
  },
  setHeight(state, height) {
    state.height = height;
  },
  setFeets(state, feets) {
    state.feets = feets;
  },
  setInches(state, inches) {
    state.inches = inches;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
