<template lang="pug">
.circle.animate-spin
  svg.circle__svg(width="120", height="120")
    circle.circle__progress.circle__progress--fill(
      cx="60",
      cy="60",
      r="50",
      ref="circle_fill"
    )
</template>
<script>
export default {
  name: "circle.loader",
};
</script>
<style lang="scss" scoped>
.circle__progress {
  fill: none;
  stroke-width: 8;
  stroke-opacity: 0.3;
  stroke-linecap: round;
}

.circle__progress {
  stroke: #71aa00;
}

.circle__progress--fill {
  --initialStroke: 0;
  stroke-opacity: 1;
  stroke-dasharray: 260;
  stroke-dashoffset: var(--initialStroke);
  filter: drop-shadow(0px 0px 6px rgba(77, 124, 15, 0.5));
}
</style>