import { ApiAxios } from './repository.config';

const RESOURCE = '/menus';

export const MenuRepository = {
  async menuReceipt(menu_id: string, receipt_id: string) {
    const { data } = await ApiAxios().get(`${RESOURCE}/${menu_id}/receipts/${receipt_id}`);
    return data;
  },

  async excludeAndReplaceReceiptMenu(menu_id: string, receipt_id: string) {
    const { data } = await ApiAxios().put(`${RESOURCE}/${menu_id}/receipts/${receipt_id}/exclude`);
    return data;
  },
  async replaceReceiptMenu(menu_id: string, receipt_id: string) {
    const { data } = await ApiAxios().put(`${RESOURCE}/${menu_id}/receipts/${receipt_id}/replace`);
    return data;
  }
};
