export interface UserImpl {
  _id: string;
  user_email: string;
  user_language: string;
  user_active: boolean;
  user_name?: string | null;
  user_post_name?: string | null;
  favorites_receipts?: string[];
}

export class UserDataEntity implements UserImpl {
  _id: string;
  user_email: string;
  user_language: string;
  user_active: boolean;
  user_name?: string | undefined | null;
  user_post_name?: string | undefined | null;
  favorites_receipts?: string[];

  constructor(partial: UserImpl) {
    this._id = partial._id;
    this.user_email = partial.user_email;
    this.user_language = partial.user_language;
    this.user_active = partial.user_active;
    this.user_name = partial.user_name;
    this.user_post_name = partial.user_post_name;
    this.favorites_receipts = partial.favorites_receipts;
  }
}
