<template lang="pug">
.relative.wave
  .container.content-wrapper
    .header
      .title-2.text-center Todo lo que haremos para <span>ti</span>
    .box-wrapper
      .box
        .icon.default
        .title-box Control diario
        .text-box Con nuestro plan de análisis avanzado controlaremos tu dieta y tus objetivos diariamente.
      .box
        .icon.blue
        .title-box Calculo en tiempo real
        .text-box Con nuestro plan de análisis avanzado controlaremos tu dieta y tus objetivos diariamente.
      .box
        .icon.yellow
        .title-box Filtros
        .text-box Con nuestro plan de análisis avanzado controlaremos tu dieta y tus objetivos diariamente.
      .box
        .icon.salmon
        .title-box Seguimiento de peso
        .text-box Con nuestro plan de análisis avanzado controlaremos tu dieta y tus objetivos diariamente.
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.wave {
  @apply bg-gray-100;
  &::before,
  &::after {
    content: "";
    background-image: url(~@/assets/wave.svg);
    background-size: cover;
    width: 100%;
    height: 130px;
    position: absolute;
  }

  &::after {
    bottom: 0;
    transform: rotate(180deg);
  }
}
.content-wrapper {
  padding: calc(120px + 3rem) 0;
}

.box-wrapper {
  @apply grid gap-8 grid-cols-4 mt-14;
}

.box {
  @apply flex flex-col items-center;

  .title-box {
    @apply text-lg font-medium mt-6 mb-4;
  }

  .text-box {
    @apply text-center text-sm text-gray-500;
  }

  .icon {
    width: 4rem;
    height: 4rem;
    @apply flex-shrink-0 rounded-md flex items-center justify-center;

    &.default {
      @apply bg-green-500;
      box-shadow: 0 20px 25px -5px rgba(132, 204, 22, 0.3),
        0 10px 10px -5px rgba(132, 204, 22, 0.5);
    }

    &.yellow {
      @apply bg-yellow-500;
      box-shadow: 0 20px 25px -5px rgba(234, 179, 8, 0.3),
        0 10px 10px -5px rgba(234, 179, 8, 0.5);
    }

    &.blue {
      @apply bg-blue-500;
      box-shadow: 0 20px 25px -5px rgba(14, 165, 233, 0.3),
        0 10px 10px -5px rgba(14, 165, 233, 0.5);
    }

    &.salmon {
      @apply bg-red-500;
      box-shadow: 0 20px 25px -5px rgba(244, 63, 94, 0.3),
        0 10px 10px -5px rgba(244, 63, 94, 0.5);
    }
  }
}
</style>
