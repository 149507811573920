<template lang="pug">
.header-receipt
  imageCloudinary.img(:image_url="heroImage", width="670", v-if="heroImage")
  .content-header
    .bg
    .content-data
      .receipt-title {{ receiptName }}
      .flex.gap-4
        .receipt-chip.type {{ receiptType }}
        .receipt-chip(v-if="prepTime") Preparación: {{ prepTime }}min
        .receipt-chip(v-if="cookTime") Cocinado: {{ cookTime }}min
</template>
<script>
export default {
  name: "image-title",
  props: {
    heroImage: {
      type: String,
      default: null,
    },
    receiptName: {
      type: String,
      default: "",
    },
    receiptType: {
      type: String,
      default: "",
    },
    prepTime: {
      type: String,
      default: "",
    },
    cookTime: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
.header-receipt {
  @apply relative overflow-hidden;
  width: 100%;
  height: 280px;
  .img {
    width: 100%;
    height: 320px;
    object-fit: cover;
    z-index: 0;
  }

  .content-header {
    @apply w-full h-full absolute top-0 z-10 flex items-end;
    .bg {
      background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.7));
      @apply w-full h-full absolute;
    }

    .content-data {
      @apply relative p-4 pb-12;
      .receipt-title {
        @apply text-white font-bold text-2xl mb-2;
      }
      .receipt-chip {
        @apply font-medium bg-blue-600 text-white inline-block text-center py-0 px-4 rounded-full text-sm;

        &.type {
          @apply bg-green-600;
        }
      }
    }
  }
}
</style>