const state = () => ({
  ingredients: [
    {
      id: 1,
      name: 'Pollo',
      image: require(`@/assets/meat_3.svg`),
      selected: true
    },
    {
      id: 2,
      name: 'Cerdo',
      image: require(`@/assets/meat_2.svg`),
      selected: true
    },
    {
      id: 3,
      name: 'Ternera',
      image: require(`@/assets/meat.svg`),
      selected: true
    },
    {
      id: 4,
      name: 'Pescado',
      image: require(`@/assets/fish_2.svg`),
      selected: true
    },
    {
      id: 5,
      name: 'Marisco',
      image: require(`@/assets/002-shrimp.svg`),
      selected: true
    },
    {
      id: 6,
      name: 'Sín carnes',
      image: require(`@/assets/no-meat.svg`),
      selected: false
    },
    {
      id: 7,
      name: 'Brócoli',
      image: require(`@/assets/brocoli_2.svg`),
      selected: true
    },
    {
      id: 8,
      name: 'Setas',
      image: require(`@/assets/seta.svg`),
      selected: true
    },
    {
      id: 9,
      name: 'Calabacín',
      image: require(`@/assets/calabacin.svg`),
      selected: true
    },
    {
      id: 10,
      name: 'Coliflor',
      image: require(`@/assets/coliflor.svg`),
      selected: true
    },
    {
      id: 11,
      name: 'Espárragos',
      image: require(`@/assets/esparrago.svg`),
      selected: true
    },
    {
      id: 12,
      name: 'Aguacate',
      image: require(`@/assets/aguacate.svg`),
      selected: true
    },
    {
      id: 13,
      name: 'Coco',
      image: require(`@/assets/coco.svg`),
      selected: true
    },
    {
      id: 14,
      name: 'Jengibre',
      image: require(`@/assets/jengibre.svg`),
      selected: true
    },
    {
      id: 15,
      name: 'Calabaza',
      image: require(`@/assets/calabaza.svg`),
      selected: true
    },
    {
      id: 16,
      name: 'Leche',
      image: require(`@/assets/leche.svg`),
      selected: true
    },
    {
      id: 17,
      name: 'Queso',
      image: require(`@/assets/queso.svg`),
      selected: true
    },
    {
      id: 18,
      name: 'Huevo',
      image: require(`@/assets/huevos.svg`),
      selected: true
    },
    {
      id: 19,
      name: 'Nueces',
      image: require(`@/assets/nuez.svg`),
      selected: true
    },
    {
      id: 20,
      name: 'Cacao',
      image: require(`@/assets/chocolate_2.svg`),
      selected: true
    },
    {
      id: 21,
      name: 'Aceitunas',
      image: require(`@/assets/olivos.svg`),
      selected: true
    }
  ]
});

const getters = {
  getMeats: state => state.ingredients.filter(ingredient => ingredient.id >= 1 && ingredient.id <= 6),
  getVegetables: state => state.ingredients.filter(ingredient => ingredient.id >= 7 && ingredient.id <= 15),
  getVarious: state => state.ingredients.filter(ingredient => ingredient.id >= 16 && ingredient.id <= 21),

  getIngredientsForm: state => {
    const ingredientsToExclude = [];
    for (const ingredient in state.ingredients) {
      if (state.ingredients[ingredient].selected == false && state.ingredients[ingredient].id != 6) {
        ingredientsToExclude.push(state.ingredients[ingredient].id);
      }
    }
    return ingredientsToExclude;
  }
};

const actions = {
  setIngredient({ commit }, ingredientId) {
    if (ingredientId == 6) {
      commit('setMeats');
    } else {
      commit('setIngredients', ingredientId);
    }
  }
};

const mutations = {
  setIngredients(state, ingredientId) {
    const option = state.ingredients.find(o => o.id === ingredientId);
    option.selected = !option.selected;
  },
  setMeats(state) {
    const option = state.ingredients.find(o => o.id === 6);
    option.selected = !option.selected;
    state.ingredients.map(ingredient => {
      for (let i = 1; i < 6; i++) {
        const meatOption = state.ingredients.find(o => o.id == i);
        meatOption.selected = !option.selected;
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
