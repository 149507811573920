import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home.vue';
import { childrensDashboard } from './dashboard.routes';

const wizard_view = () => import('../views/wizard/Wizard.viewer.vue');
const wizard_step1 = () => import('../views/wizard/Step1.vue');
const wizard_step2 = () => import('../views/wizard/Step2.vue');
const wizard_step3 = () => import('../views/wizard/Step3.vue');
const wizard_step4 = () => import('../views/wizard/Step4.vue');
const wizard_step_measures = () => import('../views/wizard/StepMeasures.vue');
const wizard_step_loading = () => import('../views/wizard/StepLoading.vue');
const wizard_step_email = () => import('../views/wizard/StepEmail.vue');
const Analysis = () => import('../views/wizard/Analysis.vue');
const Checkout = () => import('../views/Checkout/Checkout.vue');
const Payment = () => import('../views/Checkout/Payment.vue');
const ProcessPayment = () => import('../views/Checkout/ProcessPayment.vue');
const ProcessPaymentError = () => import('../views/Checkout/ProcessPaymentError.vue');
const DashboardViewer = () => import('../views/dashboard/dashboard.viewer.vue');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/quiz',
    name: 'quiz_viewer',
    component: wizard_view,
    redirect: { name: 'Quiz' },
    children: [
      {
        path: '1',
        name: 'Quiz',
        component: wizard_step1
      },
      {
        path: '2',
        name: 'Quiz_2',
        component: wizard_step2
      },
      {
        path: '3',
        name: 'Quiz_3',
        component: wizard_step3
      },
      {
        path: '4',
        name: 'Quiz_4',
        component: wizard_step4
      },
      {
        path: 'measures',
        name: 'Quiz_measures',
        component: wizard_step_measures
      },
      {
        path: 'loading',
        name: 'Quiz_loading',
        component: wizard_step_loading
      },
      {
        path: 'email',
        name: 'Quiz_email',
        component: wizard_step_email
      }
    ]
  },
  {
    path: '/analysis',
    name: 'Analysis',
    component: Analysis
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
    children: [
      {
        path: 'payment',
        name: 'Payment',
        component: Payment
      },
      {
        path: 'process-payment',
        name: 'ProcessPayment',
        component: ProcessPayment
      },
      {
        path: 'process-error',
        name: 'ProcessPaymentError',
        component: ProcessPaymentError
      }
    ]
  },
  {
    path: '/dashboard',
    component: DashboardViewer,
    children: childrensDashboard
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
