<template lang="pug">
.relative
  .blob
  .container.py-24
    h2.title Descubre la dieta cetogénica y baja en carbohidratos<span>.</span>
    p.subtitle Obten resultados en menos de <span>2 semanas</span>.
    .flex.pt-32.gap-16
      .section-image
        .image.flex.justify-start
          .relative
            .dots
            img(
              src="https://image.shutterstock.com/image-photo/happy-afro-man-white-positive-600w-349633745.jpg"
            ) 
      .section-lapses
        .flex.flex-col
          .header
            .title-2 La dieta <span>keto</span> es fácil
          .lapse-warraper
            .title-marker Pierde peso rápidamente
            p.description-marker Nuestro sistema evaluara tus datos y generara una dieta totalmente personalizada para ti, ademas, podras reajustarla a tu gusto
          .lapse-warraper
            .title-marker Optimiza diariamente tus comidas
            p.description-marker Nuestro sistema evaluara tus datos y generara una dieta totalmente personalizada para ti, ademas, podras reajustarla a tu gusto
          .lapse-warraper
            .title-marker Recetas deliciosas
            p.description-marker Nuestro sistema evaluara tus datos y generara una dieta totalmente personalizada para ti, ademas, podras reajustarla a tu gusto
          .lapse-warraper
            .title-marker Gran variedad de ingredientes
            p.description-marker Nuestro sistema evaluara tus datos y generara una dieta totalmente personalizada para ti, ademas, podras reajustarla a tu gusto
</template>

<script>
export default {
  name: "section1",
};
</script>

<style lang="scss" scoped>
h2.title {
  @apply font-extrabold;
  span {
    @apply text-green-500 font-bold;
  }
}
.subtitle {
  @apply font-medium text-xl mt-6 text-gray-500;

  span {
    @apply text-green-500 font-bold;
  }
}

.blob {
  background-image: url(~@/assets/blob_1.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 600px;
  height: 600px;
  position: absolute;
  z-index: -1;
  left: -250px;
  top: -200px;
  opacity: 0.5;
  transform: rotate(-5deg);
  &::before {
    content: "";
    background-image: url(~@/assets/avocado.svg);
    background-size: cover;
    width: 60%;
    height: 60%;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto auto;
    top: 0;
    bottom: 0;
  }
}

.section-image {
  @apply relative flex-shrink-0;
  img {
    width: 460px;
    @apply rounded-3xl;
    border-bottom-right-radius: 120px;
  }
}

.dots {
  background-image: url("~@/assets/dots.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 192px;
  height: 146px;
  position: absolute;
  z-index: -1;
  bottom: -20px;
  right: -40px;
}

.section-lapses {
  @apply py-8;

  .header {
    @apply mb-8;
  }

  .lapse-warraper {
    @apply border-l-2 border-green-400 pl-8;

    .title-marker {
      @apply font-medium flex items-center;

      &::before {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        @apply bg-green-600 p-1 border-2 border-green-300;
        margin-left: -2.4rem;
      }
    }

    .description-marker {
      @apply text-sm;
    }

    & + .lapse-warraper {
      @apply pt-8;
    }
  }
}
</style>
