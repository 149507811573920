import { SubscriptionPlanImpl } from '../entities/subcription.plan.entity';
import { ApiAxios } from './repository.config';

const RESOURCE = '/plans';

export default {
  async getSubscriptionPlans() {
    const { data } = await ApiAxios().get(RESOURCE);
    return data as SubscriptionPlanImpl[];
  }
};
