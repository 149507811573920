<template lang="pug">
.input
  .wrapper-input(:class="{ isActive: isActive }")
    label {{ labelText }}
    input(
      type="number",
      autocomplete="off",
      inputmode="decimal",
      @focus="isActive = true",
      @blur="isActive = false",
      @input="$emit('update:modelValue', $event.target.value)",
      :placeholder="placeholder"
    )
    .subtext(v-if="subText") {{ subText }}
  inputError(:showError="showError", :errorMessage="errorMessage")
</template>
<script>
export default {
  emits: ["update:modelValue"],
  components: {},
  props: {
    labelText: {
      type: String,
      default: "",
    },
    subText: {
      type: String,
      default: null,
    },
    placeholder: {
      type: [Number, String],
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Number, String],
    },
  },
  data: () => ({
    isActive: false,
  }),
};
</script>
<style lang="scss" scoped>
.wrapper-input {
  @apply ring-1 ring-inset flex items-center rounded-md px-2 py-3 relative ring-gray-200;

  &.isActive {
    @apply ring-2;
    label {
      @apply text-green-600;
    }
  }

  label {
    position: absolute;
    top: 0;
    margin-top: -8px;
    background-color: white;
    @apply px-2 leading-none text-sm font-medium;
  }

  input {
    @apply px-2;
  }

  .subtext {
    @apply text-sm font-medium text-gray-300 pr-2;
  }
}
</style>