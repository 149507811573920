<template lang="pug">
.card-payment
  .header
    Logo
  .loader
  .content
    slot(name="content")
  .footer(v-if="showFooter")
    slot(name="footer")
</template>
<script>
import { ref } from "vue";
export default {
  name: "card.payments",
  setup(props, { slots }) {
    const showFooter = ref(!!slots.footer);
    return { showFooter };
  },
};
</script>
<style lang="scss" scoped>
.card-payment {
  @apply bg-white relative overscroll-contain overflow-auto overflow-x-hidden border-2 rounded-lg w-full border-green-500;
  box-shadow: 0 10px 15px -5px rgba(132, 204, 22, 0.2),
    0 5px 10px -5px rgba(132, 204, 22, 0.1);
  max-width: 440px;
  .header,
  .content {
    @apply p-4;
  }

  .loader {
    height: 4px;
    width: 100%;
    @apply bg-green-100 relative;

    &::before {
      content: "";
      position: absolute;
      height: 100%;
      @apply bg-green-400;
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      transition: width 1s ease-in-out;
      width: 100%;
    }
  }

  .content {
    @apply pb-2;
  }

  .footer {
    @apply pb-4 px-4 bg-gray-50 pt-4;
  }
}
</style>