import { setToken, getToken, removeToken } from '@/app/repository_storage/auth.storage';

import router from '@/router';

const token = getToken();

const state = () => ({
  stateAuth: token ? true : false
});

const getters = {
  isLogged: state => state.stateAuth
};

const actions = {
  async autoLogin({ commit }, access_token) {
    commit('loggedIn', { token: access_token, replace: true });
    return { success: true };
  }
};

const mutations = {
  loggedIn(state, { token, replace = false }) {
    state.stateAuth = true;
    setToken(token);
    if (replace) {
      router.replace({ name: 'Dashboard' });
    } else {
      router.push({ name: 'Dashboard' });
    }
  },
  logout(state) {
    state.stateAuth = false;
    removeToken();
    router.push({ name: 'Home' });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
