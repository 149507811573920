<template lang="pug">
.form-title 
  slot
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.form-title {
  @apply text-xl font-bold;
}
</style>