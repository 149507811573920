<template lang='pug'>
.error(v-if="showError") {{ errorMessage }}
</template>
<script>
export default {
  props: {
    errorMessage: {
      type: String,
      default: null,
    },
    showError: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.error {
  @apply text-xs text-red-500 font-medium pt-1 px-4;
}
</style>