import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import './tailwind.scss';

import VueAnimXYZ from '@animxyz/vue3';
import '@animxyz/core'; // Import css here if you haven't elsewhere

import SvgIcon from '@jamescoyle/vue-icon';

import { registerComponents } from '@/plugins/componentLoader';

const app = createApp(App);

registerComponents(app);

app.component('svgIcon', SvgIcon);
app.use(VueAnimXYZ);

app
  .use(store)
  .use(router)
  .mount('#app');
