<template lang="pug">
XyzTransition(appear, xyz="fade")
  .wrapper-loader(v-if="show")
    circleLoader
</template>
<script>
export default {
  name: "absolute.loader",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-loader {
  @apply absolute top-0 left-0 w-full h-full flex items-center justify-center;
  &::before {
    content: "";
    @apply bg-white w-full h-full absolute opacity-75;
  }
}
</style>