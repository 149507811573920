import { ApiAxios } from './repository.config';

const RESOURCE = '/users';

export interface CreateAccountImpl {
  data_user: {
    email: string;
    lang: string;
  };
  data_metrics: {
    gender: 'male' | 'female';
    age: number;
    metric: 'metric' | 'imperial';
    feets: number | null;
    inches: number | null;
    height: number | null;
    weight: number;
    weightObjective: number;
    activity: number;
  };
  excluded_ingredients: number[];
}

export const UserRepository = {
  async createAccountUser(createAccountData: CreateAccountImpl) {
    try {
      const { data } = await ApiAxios().post(RESOURCE, {
        ...createAccountData
      });
      return data;
    } catch (e) {
      return e.response.data;
    }
  },

  async me() {
    try {
      const { data } = await ApiAxios().get(`${RESOURCE}/me`);
      return data;
    } catch (e) {
      return e.response.data;
    }
  },

  async meMenus() {
    try {
      const { data } = await ApiAxios().get(`${RESOURCE}/me/menus`);
      return data;
    } catch (e) {
      return e.response.data;
    }
  },

  async meCompletMenu(menu_id: string) {
    const { data } = await ApiAxios().get(`${RESOURCE}/me/menus/${menu_id}`);
    return data;
  },

  async addReceiptToFavorites(receipt_id: string) {
    await ApiAxios().post(`${RESOURCE}/me/favorites/${receipt_id}`);
  },

  async removeReceiptToFavorites(receipt_id: string) {
    await ApiAxios().delete(`${RESOURCE}/me/favorites/${receipt_id}`);
  }
};
