<template lang="pug">
.input
  .wrapper-input(:class="{ isActive: isActive, error: showError }")
    label(v-if="labelText") {{ labelText }}
    svgIcon.icon(v-if="iconPath", :path="iconPath", type="mdi", :size="24")
    input(
      :type="inputType",
      @focus="isActive = true",
      @blur="isActive = false",
      @input="$emit('update:modelValue', $event.target.value)",
      :placeholder="placeholder"
    )
    .subtext(v-if="subText") {{ subText }}
    .insetActionButton(v-if="insetActionButton", @click="$emit('clickButton')") {{ insetActionButton }}
  inputError(:showError="showError", :errorMessage="errorMessage")
</template>
<script>
export default {
  emits: ["update:modelValue", "clickButton"],
  components: {},
  props: {
    labelText: {
      type: String,
      default: "",
    },
    subText: {
      type: String,
      default: null,
    },
    iconPath: {
      type: String,
      default: null,
    },
    insetActionButton: {
      type: String,
      default: null,
    },
    inputType: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: [Number, String],
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    showError: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [Number, String],
    },
  },
  data: () => ({
    isActive: false,
  }),
};
</script>
<style lang="scss" scoped>
.wrapper-input {
  @apply ring-1 ring-inset flex items-center rounded-md px-2 py-3 relative ring-gray-200;

  .icon {
    @apply text-gray-300 ml-1 transition-colors flex-shrink-0;
  }

  .insetActionButton {
    @apply bg-green-600 text-white px-4 py-1 rounded -mt-1 -mb-1 cursor-pointer transition-colors;

    &:hover {
      @apply bg-green-700;
    }
  }

  &.isActive {
    @apply ring-2;
    label {
      @apply text-green-600;
    }

    .icon {
      @apply text-green-600;
    }
  }

  &.error {
    @apply ring-red-200;
  }

  label {
    position: absolute;
    top: 0;
    margin-top: -8px;
    background-color: white;
    @apply px-2 leading-none text-sm font-medium;
  }

  input {
    @apply px-2;
  }

  .subtext {
    @apply text-sm font-medium text-gray-300 pr-2;
  }
}
</style>