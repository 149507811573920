<template lang="pug">
.wrapper-default-card.relative
  .header-receipt
    imageCloudinary.img(:image_url="receipt.receipt_heroImage", width="420")
    .content-header
      .bg
      .content-data 
        .receipt-chip.type {{ receipt.receipt_category.category }}
        .receipt-title {{ receipt.receipt_name }}
        .flex.gap-4
          .receipt-chip(v-if="receipt.receipt_prepTime") Preparación: {{ receipt.receipt_prepTime }}min
          .receipt-chip(v-if="receipt.receipt_cookTime") Cocinado: {{ receipt.receipt_cookTime }}min
  .absolute.-bottom-3.w-full
    .flex.justify-end.px-4
      buttonDashboard(text="Ver Receta", @click="buttonShowReceipt")
modal(:show="showReceipt", :disable_close="true")
  customReceiptWrapper(:receipt_id="receipt._id")
</template>
<script>
import { toRefs, ref, provide } from "vue";
import customReceiptWrapper from "@/components/modal-receipt/wrappers/custom-receipt-wrapper.vue";

export default {
  name: "default.card",
  props: {
    receipt: Object,
  },
  components: {
    customReceiptWrapper,
  },
  setup(props) {
    const { receipt } = toRefs(props);
    const showReceipt = ref(false);

    const buttonShowReceipt = () => {
      showReceipt.value = true;
    };
    const buttonCloseReceipt = () => (showReceipt.value = false);
    provide("buttonCloseReceipt", buttonCloseReceipt);

    return {
      showReceipt,
      buttonShowReceipt,
    };
  },
};
</script>
<style lang="scss" scoped>
.wrapper-default-card {
  @apply rounded-lg border-2 border-gray-100;
}

.header-receipt {
  @apply relative overflow-hidden rounded-lg;
  width: 100%;
  .img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    z-index: 0;
  }

  .content-header {
    @apply w-full h-full absolute top-0 flex items-end;
    .bg {
      background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8));
      @apply w-full h-full absolute;
    }

    .content-data {
      @apply relative p-4 pb-12;
      .receipt-title {
        @apply text-white font-bold text-2xl mb-2;
      }
      .receipt-chip {
        @apply font-medium bg-blue-600 text-white inline-block text-center py-0 px-4 rounded-full text-sm;

        &.type {
          @apply bg-green-600;
        }
      }
    }
  }
}
</style>