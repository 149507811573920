<template lang="pug">
.group-brand
  .icon
    .logo 
  .name ketoplan
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.group-brand {
  @apply flex items-center;
}
.icon {
  width: 2rem;
  height: 2rem;
  @apply bg-green-500 flex-shrink-0 rounded-md mr-2 flex items-center justify-center;

  .logo {
    background-image: url(~@/assets/logo.svg);
    background-size: cover;
    background-repeat: no-repeat;
    width: 18px;
    height: 18px;
  }
}

.name {
  @apply text-lg font-semibold text-gray-500;
}
</style>