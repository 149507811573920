<template lang="pug">
h1 {{ title }}
</template>
<script>
export default {
  name: "dashbaord.title",
  props: {
    title: {
      type: String,
      default: "Titulo",
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  @apply text-xl font-semibold text-gray-400 uppercase pb-4;
}
</style>