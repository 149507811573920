<template lang="pug">
modal(:show="showModal", :disable_close="true")
  cardPayments
    template(v-slot:content)
      p.text-sm.text-center se deshabilitara de forma permanente y no la volveremos a añadir en el futuro.
      p.text-sm.text-center.mt-2 Tambien la sustituiremos con otra deliciosa receta en el menu actual.
      p.text-sm.mt-2.text-center Siempre podras volver a habilitarla desde la configuracion de menu.
      absoluteLoader(:show="showLoader")
    template(v-slot:footer)
      .flex.justify-end
        buttonDashboard(
          text="Cancelar",
          color="gray",
          @click="$emit('closeModal')"
        )
        buttonDashboard.ml-2(
          text="excluir",
          color="default",
          @click="excludeReceipt"
        )
</template>
<script>
import { useStore } from "vuex";
import { inject, ref, toRefs } from "vue";
import { MenuRepository } from "@/app/repository/menu.repository";

export default {
  name: "modal-exclude-receipt",
  emits: ["closeModal"],
  props: {
    menuId: {
      type: String,
    },
    receiptAdaptedId: {
      type: String,
    },
    showModal: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const store = useStore();
    const { menuId, receiptAdaptedId } = toRefs(props);
    const reloadReceipt = inject("reloadReceipt");

    const showLoader = ref(false);
    const excludeReceipt = async () => {
      showLoader.value = true;
      await MenuRepository.excludeAndReplaceReceiptMenu(
        menuId.value,
        receiptAdaptedId.value
      );
      await store.dispatch("menuStore/updateDataMenu", menuId.value);
      showLoader.value = false;
      reloadReceipt();
      emit("closeModal");
    };

    return {
      showLoader,
      excludeReceipt,
    };
  },
};
</script>
<style lang="scss" scoped>
</style>