import fitnessCalculatorFunctions from 'fitness-calculator';

import { roundNoneDecimal, roundToTwoDecimal } from '@/app/services/transformMetrics';

export type Metric = 'metric' | 'imperial';
export type Gender = 'male' | 'female';
export type Activity = 'sedentary' | 'light' | 'moderate' | 'active' | 'extreme';

export enum ActivityFrecuency {
  'sedentary',
  'light',
  'moderate',
  'active',
  'extreme'
}

export type groupsCalories = {
  balance: number;
  mildWeightLoss: number;
  mildWeightGain: number;
  heavyWeightLoss: number;
  heavyWeightGain: number;
};

enum CategoryBMI {
  bajo,
  normal,
  alto,
  extraAlto
}

export class KetoCalculator {
  WATER_CALC = 0.035;

  constructor() {}

  calculateBmi(height: number, weight: number) {
    return fitnessCalculatorFunctions.BMI(height, weight);
  }

  calculateBmiImperial(feets: number, inches: number, weightLb: number) {
    return this.calculateBmi(this.ftInToCm(feets, inches), this.lbToKg(weightLb));
  }

  bmiCategory(bmi: number) {
    if (bmi < 18.5) return CategoryBMI.bajo; //delgado
    if (bmi > 18.5 && bmi <= 22.9) return CategoryBMI.normal; //saludable
    if (bmi >= 23 && bmi <= 29.9) return CategoryBMI.alto; //obeso
    if (bmi >= 30) return CategoryBMI.extraAlto; //muy obeso
  }

  calculateMetabolicAge(bmiCategory: number, realAge: number) {
    if (bmiCategory == CategoryBMI.normal) {
      return realAge;
    }
    if (bmiCategory == CategoryBMI.bajo) {
      const age = realAge * 0.3;
      return roundNoneDecimal(realAge - age);
    }
    if (bmiCategory == CategoryBMI.alto) {
      const age = realAge * 0.1;
      return roundNoneDecimal(realAge + age);
    }
    if (bmiCategory == CategoryBMI.extraAlto) {
      const age = realAge * 0.2;
      return roundNoneDecimal(realAge + age);
    }
  }

  calculateBmr(gender: Gender, age: number, height: number, weight: number) {
    return fitnessCalculatorFunctions.BMR(gender, age, height, weight);
  }

  calculateBmrImperial(gender: Gender, age: number, feets: number, inches: number, weightLb: number) {
    return fitnessCalculatorFunctions.BMR(gender, age, this.ftInToCm(feets, inches), this.lbToKg(weightLb));
  }

  calculateIdealWeigth(gender: Gender, height: number) {
    return fitnessCalculatorFunctions.idealBodyWeight(gender, height);
  }

  calculateCalories(gender: Gender, age: number, height: number, weight: number, activity: Activity): groupsCalories {
    return fitnessCalculatorFunctions.calorieNeeds(gender, age, height, weight, activity);
  }

  calculateCaloriesImperial(gender: Gender, age: number, feet: number, inches: number, weight: number, activity: Activity) {
    return this.calculateCalories(gender, age, this.ftInToCm(feet, inches), this.lbToKg(weight), activity);
  }

  calculateWater(weight: number) {
    return roundToTwoDecimal(weight * this.WATER_CALC);
  }

  calculateWaterImperial(weight: number) {
    return this.calculateWater(this.lbToKg(weight));
  }

  calculateMacros(calories: number) {
    return {
      carb: roundToTwoDecimal((0.05 * calories) / 4),
      protein: roundToTwoDecimal((0.2 * calories) / 4),
      fat: roundToTwoDecimal((0.75 * calories) / 9)
    };
  }

  calculateWeightAfterMonth(actualWeight: number, objectiveWeight: number) {
    console.log(actualWeight, objectiveWeight);
    const pesoAperder = actualWeight - objectiveWeight;
    const weightLossFirstMonth = pesoAperder * 0.4;
    const weightLossPerWeek = weightLossFirstMonth / 4;
    const today = new Date();
    const arrayWeeks = [];
    for (let index = 1; index <= 4; index++) {
      arrayWeeks.push({
        date: new Date(today.setDate(today.getDate() + 7)),
        weight: roundNoneDecimal(actualWeight - weightLossPerWeek * index)
      });
    }
    return {
      totalWeightLossFirstMonth: roundToTwoDecimal(weightLossFirstMonth),
      lossObjective: roundToTwoDecimal(pesoAperder),
      arrayWeeks
    };
  }

  lbToKg = (weight: number) => weight * 0.453592;
  kgToLb = (weight: number) => weight / 0.453592;
  ftInToCm = (feets: number, inches: number) => feets * 30.48 + inches * 2.54;
}
