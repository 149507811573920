import { UserRepository } from '@/app/repository/user.repository';

const sumMacros = receipts =>
  receipts.reduce(
    (previous, current) => {
      return {
        carb: previous.carb + current.receipt_macros.carb,
        fats: previous.fats + current.receipt_macros.fats,
        kcal: previous.kcal + current.receipt_macros.kcal,
        protein: previous.protein + current.receipt_macros.protein
      };
    },
    {
      carb: 0,
      fats: 0,
      kcal: 0,
      protein: 0
    }
  );

const state = () => ({
  menu_id: null,
  receipts: [],
  excluded_ingredients: [],
  excluded_receipts: []
});

const getters = {
  getMenuId: state => state.menu_id,
  getReceiptsSelectedDay: (state, getters, rootState, rootGetters) => {
    const selectedDay = rootGetters['calendarStore/selectedDay'];
    if (state.receipts.length > 0) {
      return state.receipts.filter(receipt => receipt.receipt_date == selectedDay.toISOString());
    } else {
      return [];
    }
  },
  getMacrosSelectedDay: (state, getters, rootState, rootGetters) => {
    const receipts = getters.getReceiptsSelectedDay;
    return sumMacros(receipts);
  },

  getReceiptsSelectedWeek: (state, getters, rootState, rootGetters) => {
    const selectedDay = rootGetters['calendarStore/completeWeek'];
    if (state.receipts.length > 0) {
      return state.receipts.filter(
        receipt =>
          receipt.receipt_date >= selectedDay[0].toISOString() && receipt.receipt_date <= selectedDay[selectedDay.length - 1].toISOString()
      );
    } else {
      return [];
    }
  },

  getMacrosSelectedWeek: (state, getters, rootState, rootGetters) => {
    const receipts = getters.getReceiptsSelectedWeek;
    return sumMacros(receipts);
  },
  getPercentMacrosConsumed: (state, getters, rootState, rootGetters) => {
    const selectedDay = rootGetters['calendarStore/completeWeek'];
    const today = rootGetters['calendarStore/today'];
    const receipts = state.receipts.filter(
      receipt => receipt.receipt_date >= selectedDay[0].toISOString() && receipt.receipt_date <= today.toISOString()
    );
    return sumMacros(receipts);
  }
};

const actions = {
  async getDataMenu({ commit, getters }, menu_id) {
    commit('setMenuId', menu_id);
    const menuData = await UserRepository.meCompletMenu(menu_id);
    commit('setReceipts', menuData.receipts);
  },
  async updateDataMenu({ commit, getters }, menu_id) {
    commit('setMenuId', menu_id);
    const menuData = await UserRepository.meCompletMenu(menu_id);
    commit('updateReceipts', menuData.receipts);
  }
};

const mutations = {
  setMenuId(state, menu_id) {
    state.menu_id = menu_id;
  },
  setReceipts(state, receipts) {
    state.receipts = receipts;
  },
  updateReceipts(state, receipts) {
    for (const iterator of receipts) {
      const indexStateReceipt = state.receipts.findIndex(r => r._id == iterator._id);
      state.receipts.splice(indexStateReceipt, 1, iterator);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
