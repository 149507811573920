const DashboardPrincipal = () => import('../views/dashboard/dashboard.principal.vue');
const MenuConfiguration = () => import('../views/dashboard/menu.configuration.vue');
const ReceiptsFinder = () => import('../views/dashboard/receipts.finder.vue');

export const childrensDashboard = [
  {
    path: '',
    name: 'Dashboard',
    component: DashboardPrincipal
  },
  {
    path: '/menu-configuration',
    name: 'MenuConfiguration',
    component: MenuConfiguration
  },
  {
    path: '/finder',
    name: 'ReceiptsFinder',
    component: ReceiptsFinder
  }
];
