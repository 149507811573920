const setDataAnalysis = ({
  gender,
  age,
  metric,
  feets,
  inches,
  height,
  weight,
  weightObjective,
  activity
}) => {
  const dataParsed = JSON.stringify({
    gender,
    age,
    metric,
    feets,
    inches,
    height,
    weight,
    weightObjective,
    activity
  });
  localStorage.setItem('dataAnalysis', dataParsed);
};

const getDataAnalysis = () => JSON.parse(localStorage.getItem('dataAnalysis'));

export { setDataAnalysis, getDataAnalysis };
