type metric = 'metric' | 'imperial';

const lbToKg = (weight: number) => weight * 0.453592;

const kgToLb = (weight: number) => weight / 0.453592;

const metricType = (metric: metric) => (metric == 'metric' ? 'kg' : 'lb');

const roundToTwoDecimal = (number: number) => Number(number.toFixed(2));

const roundNoneDecimal = (number: number) => Number(number.toFixed(0));

export { lbToKg, kgToLb, metricType, roundNoneDecimal, roundToTwoDecimal };
