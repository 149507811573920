<template lang='pug'>
.input-checkbox(@click="$emit('update:modelValue', !modelValue)")
  .checkbox(:class="{ error: error }")
    svgIcon(:path="mdiCheckBold", :size="16", type="mdi", v-if="isChecked")
  label {{ labelText }}
</template>
<script>
import { mdiCheckBold } from "@mdi/js";
export default {
  emits: ["update:modelValue"],
  data: () => ({
    mdiCheckBold,
  }),
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      return this.modelValue;
    },
  },
};
</script>
<style lang="scss" scoped>
.input-checkbox {
  @apply flex items-center;

  label {
    @apply px-2 leading-none text-sm font-medium cursor-pointer;
  }
}
.checkbox {
  width: 1.2rem;
  height: 1.2rem;
  @apply border-2 rounded cursor-pointer relative flex-shrink-0;

  svg {
    margin-top: -1px;
    margin-left: -1px;
    @apply absolute top-0 left-0 text-green-600;
  }
  &.error {
    @apply border-red-200;
  }
}
</style>