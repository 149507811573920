<template lang="pug">
NavBar
router-view
</template>

<script>
import NavBar from "@/components/shared/navBar";
export default {
  components: {
    NavBar,
  },
  async created() {
    await this.$store.dispatch("plansSubscriptionsStore/fetchPlans");
  },
};
</script>
<style lang="scss">
</style>
