import { UserDataEntity } from '@/app/entities/user.data.entity';
import { UserRepository } from '@/app/repository/user.repository';
import { CreateAccountImpl } from '@/app/repository/user.repository';

export default {
  async createAccount(accountData: CreateAccountImpl) {
    const userData = await UserRepository.createAccountUser(accountData);
    if (userData.error) return Promise.reject(userData);
    return new UserDataEntity(userData);
  },

  async getMe() {
    const userData = await UserRepository.me();
    return new UserDataEntity(userData);
  }
};
