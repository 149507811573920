<template lang="pug">
.relative
  .container.py-16
    .flex.gap-16.items-center
      .section-lapses
        .flex.flex-col
          .header
            .title-2 Recetas <span>keto</span> adaptadas a ti
          .lapse-warraper
            .flex.flex-col
              .header-group
                .icon
                  .clock
                .title-marker Ahorra tiempo
              p.description-marker Nuestro sistema evaluara tus datos y generara una dieta totalmente personalizada para ti, ademas, podras reajustarla a tu gusto
          .lapse-warraper
            .flex.flex-col
              .header-group
                .icon
                  .note
                .title-marker Plan alimenticio personalizado
              p.description-marker Nuestro sistema evaluara tus datos y generara una dieta totalmente personalizada para ti, ademas, podras reajustarla a tu gusto
          .lapse-warraper
            .flex.flex-col
              .header-group
                .icon
                  .settings
                .title-marker Análisis continuado
              p.description-marker Nuestro sistema evaluara tus datos y generara una dieta totalmente personalizada para ti, ademas, podras reajustarla a tu gusto
      .section-image
        .image.flex.justify-start
          .relative
            .dots
            img(src="@/assets/salmon.jpg")
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.section-image {
  @apply relative flex-shrink-0;
  img {
    width: 460px;
    @apply rounded-3xl;
    border-top-left-radius: 120px;
  }
}

.clock {
  background-image: url(~@/assets/clock.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
}
.note {
  background-image: url(~@/assets/note.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
}
.settings {
  background-image: url(~@/assets/setting.svg);
  background-size: cover;
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
}
.dots {
  background-image: url("~@/assets/dots.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 192px;
  height: 146px;
  position: absolute;
  z-index: -1;
  top: -80px;
  left: -80px;
}

.section-lapses {
  @apply py-8;

  .header {
    @apply mb-8;
  }

  .lapse-warraper {
    @apply border-2 p-4 rounded-2xl border-green-100;
    .title-marker {
      @apply font-medium flex items-center;
    }

    .description-marker {
      @apply text-sm;
    }
    & + .lapse-warraper {
      margin-top: 1rem;
    }
  }

  .header-group {
    @apply flex items-center mb-2;
  }
  .icon {
    width: 2rem;
    height: 2rem;
    @apply bg-green-200 flex-shrink-0 rounded-md mr-2 flex items-center justify-center;
  }
}
</style>
