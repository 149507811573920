import SubscriptionPlansService from '@/app/services/plansSubscription/plan.subscription.service';

const state = () => ({
  plans: [],
  selectedPlanID: null
});

const getters = {
  getListOrdered: state => state.plans.sort((a, b) => a.billing_months - b.billing_months).reverse(),
  getSelectedPlan: state => state.selectedPlanID,
  getPlan: state => state.plans.filter(p => p._id == state.selectedPlanID)[0]
};

const actions = {
  async fetchPlans({ commit, state, getters }) {
    const plansEntities = await SubscriptionPlansService.getPlans();
    commit('setPlans', plansEntities);
    if (state.selectedPlanID == null) commit('setSelectedPlan', getters.getListOrdered[0]._id);
  },
  async changePlan({ commit }, plan_id) {
    commit('setSelectedPlan', plan_id);
  }
};

const mutations = {
  setPlans(state, plans) {
    state.plans = plans;
  },
  setSelectedPlan(state, plan_id) {
    state.selectedPlanID = plan_id;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
