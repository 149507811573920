<template lang="pug">
.wrapper-skeleton
  .image
  .content
    .line
    .line2
    .flex.gap-8.mt-4
      .box-macro
      .box-macro
      .box-macro
      .box-macro
</template>
<script>
export default {
  name: "skeleton.card",
};
</script>
<style lang="scss" scoped>
.wrapper-skeleton {
  @apply rounded-lg border-2 border-gray-100 overflow-hidden;

  .image {
    @apply w-full h-56 bg-gray-200;
  }

  .image,
  .line,
  .line2 {
    animation: pulse 2s infinite ease-in-out;
  }

  .content {
    @apply p-4;
    .line,
    .line2 {
      width: 30%;
      height: 12px;
      @apply rounded-full;
    }

    .line2 {
      width: 70%;
      height: 24px;
      @apply mt-2;
    }

    .box-macro {
      @apply bg-gray-100 h-8 w-full rounded-full;
    }
  }
}

@keyframes pulse {
  0% {
    @apply bg-gray-100;
  }

  50% {
    @apply bg-gray-200;
  }

  100% {
    @apply bg-gray-100;
  }
}
</style>