<template lang="pug">
img(v-if="image_url", :src="urlFormated")
</template>
<script>
import { toRefs, computed } from "vue";
export default {
  name: "image.cloudinary",
  props: {
    image_url: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: null,
    },
  },

  setup(props) {
    const { image_url, width, height } = toRefs(props);

    const urlFormated = computed(() => {
      return `https://res.cloudinary.com/secure-online-managements-s-l/image/upload/w_${width.value},c_scale/${image_url.value}.png`;
    });

    return {
      urlFormated,
    };
  },
};
</script>
<style lang="scss" scoped>
</style>