<template lang="pug">
.navBar(v-if="showNavBar")
  .container
    .flex.justify-between.items-center
      router-link(:to="{ name: 'Home' }") 
        Logo
      ul.links
        li Soporte
        li 
          .button-loggin(@click="showModalLogin = true")
            svgIcon(:path="mdiAccount", type="mdi", :size="16")
            | Acceder
teleport(to="body")
  modal(:show="showModalLogin", v-on:close="showModalLogin = false")
    cardPayments.-mt-40
      template(v-slot:content)
        component(
          :is="currentTabComponent",
          v-on:changeTab="changeTab",
          v-on:closeLogin="showModalLogin = false"
        ) 
</template>

<script>
import { mdiAccount } from "@mdi/js";
import { defineAsyncComponent } from "vue";

const AccessComponentAsync = defineAsyncComponent(() =>
  import("@/components/login/access.vue")
);
const RememberComponentAsync = defineAsyncComponent(() =>
  import("@/components/login/remember.vue")
);
export default {
  components: {
    AccessComponentAsync,
    RememberComponentAsync,
  },
  data: () => ({
    mdiAccount,
    actualRoute: null,
    showModalLogin: false,
    currentTab: "Access",
  }),
  watch: {
    "$route.name": {
      handler: function (nValue) {
        this.actualRoute = nValue;
      },
      deep: true,
      inmediate: true,
    },
  },
  methods: {
    changeTab(tabName) {
      this.currentTab = tabName;
    },
  },
  computed: {
    showNavBar() {
      return ["Home"].includes(this.actualRoute) ? true : false;
    },
    currentTabComponent() {
      return this.currentTab + "ComponentAsync";
    },
  },
};
</script>

<style lang="scss" scoped>
.navBar {
  height: 65px;
  @apply w-full flex items-center px-4;
}

.links {
  @apply flex items-center text-sm font-medium;
  li + li {
    margin-left: 1rem;
  }
}

.button-loggin {
  @apply bg-green-500 text-white font-medium pl-2 pr-4 py-1 rounded-md text-base flex items-center transition-colors;
  svg {
    @apply mr-1;
  }

  &:hover {
    @apply bg-green-200 text-green-600 cursor-pointer;
    box-shadow: 0 10px 15px -5px rgba(132, 204, 22, 0.2),
      0 5px 10px -5px rgba(132, 204, 22, 0.1);
  }
}
</style>
