<template lang="pug">
.cta {{ textButton }}
  svgIcon.icon(:path="mdiChevronRight", type="mdi", :size="24")
</template>
<script>
import { mdiChevronRight } from "@mdi/js";
export default {
  props: {
    textButton: {
      type: String,
      default: "Empty",
    },
  },
  data: () => ({
    mdiChevronRight,
  }),
};
</script>
<style lang="scss" scoped>
.cta {
  @apply cursor-pointer border-2 border-green-400 pr-6 pl-8 py-3 rounded-lg bg-green-50 font-bold text-green-700 flex items-center justify-center transition-shadow shadow-none;

  .icon {
    @apply ml-2;
  }

  &:hover {
    box-shadow: 0 10px 15px -5px rgba(132, 204, 22, 0.2),
      0 5px 10px -5px rgba(132, 204, 22, 0.1);
    .icon {
      animation: slide 0.25s ease-in-out;
    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(15px) scale(0.5);
    opacity: 0.1;
  }
}
</style>