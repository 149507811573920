<template lang="pug">
teleport(to="body")
  XyzTransition(appear, xyz="fade big-5 duration-2")
    .modal-back(v-if="show")
      .flex.justify-center.items-center.w-full.h-full.z-10
        .modal-close(@click="$emit('close')", v-if="!disable_close")
          svg-icon(type="mdi", :size="32", :path="mdiClose")
        slot
</template>
<script>
import { mdiClose } from "@mdi/js";
export default {
  name: "modalBlur",
  emits: ["close"],
  props: {
    show: Boolean,
    disable_close: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mdiClose,
  }),
};
</script>
<style lang="scss" scoped>
.modal-back {
  @apply fixed top-0 left-0 w-full h-full z-40 p-4;
  backdrop-filter: blur(3px);
  &::before {
    content: "";
    z-index: -1;
    @apply w-full h-full bg-white opacity-50 absolute top-0 left-0;
  }
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    @apply text-green-500 cursor-pointer z-50;
    &:hover {
      @apply text-blue-500;
    }
  }
}
</style>