import { UserRepository } from '@/app/repository/user.repository';

const state = () => ({
  menus: []
});

const getters = {
  getLastMenuGenerated: state => state.menus[state.menus.length - 1]
};

const actions = {
  async getMeMenus({ commit, dispatch, getters }) {
    const menus = await UserRepository.meMenus();
    commit('setMenus', menus);
    dispatch('menuStore/getDataMenu', getters['getLastMenuGenerated']._id, { root: true });
  }
};

const mutations = {
  setMenus(state, menus) {
    state.menus = menus;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
