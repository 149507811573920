<template lang="pug">
.button(:class="color") {{ text }}
</template>
<script>
export default {
  name: "button.dashboard",
  props: {
    text: {
      type: String,
      default: "Empty",
    },
    color: {
      type: String,
      default: "default",
    },
  },
};
</script>
<style lang="scss" scoped>
.button {
  @apply inline-block py-2 px-4 rounded-md font-medium cursor-pointer transition-colors text-center;

  &.gray {
    @apply bg-gray-200 text-gray-400;
  }

  &.gray:hover {
    @apply bg-gray-300 text-gray-500;
  }

  &.default {
    @apply bg-green-600 text-white;
  }

  &.default:hover {
    @apply bg-green-500;
  }
}
</style>