<template lang="pug">
.group-options
  svg-icon.close-button(
    :path="mdiClose",
    type="mdi",
    :size="32",
    @click="$emit('buttonCloseReceipt')"
  )
  .flex.items-center
    .add-to-favorites(
      @click="$emit('addReceiptToFavorites')",
      v-if="!isFavoriteReceipt"
    ) 
      svg-icon.like-button(:path="mdiHeartOutline", type="mdi", :size="24")
      | Añadir a favoritas
    .add-to-favorites(@click="$emit('pullReceiptFromFavorites')", v-else) 
      svg-icon.like-button(:path="mdiHeart", type="mdi", :size="24")
      | Eliminar de favoritas
    menuOptions

.group-menu
  .option(
    @click="$emit('setCurrentTab', 'macros')",
    v-bind:class="{ active: currentTab == 'macros' }"
  ) Macronutrientes
  .option(
    @click="$emit('setCurrentTab', 'ingredients')",
    v-bind:class="{ active: currentTab == 'ingredients' }"
  ) Ingredientes
  .option(
    @click="$emit('setCurrentTab', 'receipt')",
    v-bind:class="{ active: currentTab == 'receipt' }"
  ) Receta
  .option(
    @click="$emit('setCurrentTab', 'nutrition')",
    v-bind:class="{ active: currentTab == 'nutrition' }"
  ) Nutrición
</template>
<script>
import { mdiClose, mdiHeart, mdiHeartOutline, mdiDotsVertical } from "@mdi/js";
import menuOptions from "../menu-options/menu.options.vue";

export default {
  name: "nav-header",
  components: {
    menuOptions,
  },
  props: {
    currentTab: {
      type: String,
      default: "macros",
    },
    isFavoriteReceipt: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "buttonCloseReceipt",
    "addReceiptToFavorites",
    "pullReceiptFromFavorites",
    "setCurrentTab",
  ],
  data: () => ({
    mdiClose,
    mdiHeart,
    mdiHeartOutline,
    mdiDotsVertical,
  }),
};
</script>
<style lang="scss" scoped>
.group-options {
  @apply flex justify-between px-4 py-4 items-center;
  .close-button {
    @apply text-green-600 cursor-pointer;
  }

  .add-to-favorites {
    @apply bg-red-500 text-white font-bold flex justify-center items-center py-2 pr-4 pl-2 rounded-lg cursor-pointer;

    .like-button {
      @apply text-white mr-2;
    }
  }
}

.group-menu {
  @apply w-full border-b-2 border-t-2 border-gray-200 px-4 grid grid-cols-4 gap-2 text-sm sticky top-0 z-40 bg-white;
  .option {
    @apply text-center py-1 my-2 cursor-pointer rounded-full;

    &:hover {
      @apply bg-gray-100;
    }

    &.active {
      @apply font-semibold bg-green-600 text-white;
    }
  }
}
</style>