const d = new Date();
const today = new Date(d.getFullYear(), d.getMonth(), d.getDate());

const getMonday = d => {
  d = new Date(d);
  const day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};

const state = () => ({
  selectedDay: today
});

const getters = {
  today: state => today,
  selectedDay: state => new Date(state.selectedDay.getFullYear(), state.selectedDay.getMonth(), state.selectedDay.getDate()),
  completeWeek: (state, getters) => {
    const firstDayOfWeek = getMonday(getters.selectedDay);
    const arrayDay = [];
    for (let index = 0; index < 7; index++) {
      arrayDay.push(new Date(firstDayOfWeek.getFullYear(), firstDayOfWeek.getMonth(), firstDayOfWeek.getDate() + index));
    }
    return arrayDay;
  }
};

const actions = {
  sumDay({ commit, state, getters }) {
    const newDate = new Date(state.selectedDay.getFullYear(), state.selectedDay.getMonth(), state.selectedDay.getDate() + 1);
    if (newDate <= getters.completeWeek[6]) commit('changeSelectedDay', newDate);
  },
  subDay({ commit, state, getters }) {
    const newDate = new Date(state.selectedDay.getFullYear(), state.selectedDay.getMonth(), state.selectedDay.getDate() - 1);
    if (newDate >= getters.completeWeek[0]) commit('changeSelectedDay', newDate);
  }
};

const mutations = {
  changeSelectedDay(state, day) {
    state.selectedDay = day;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
