import { MenuRepository } from '@/app/repository/menu.repository';
import { identificationReceipt } from '@/app/composables/identificationReceipt';

const state = () => ({
  receipt: {}
});

const getters = {
  getAdaptedReceiptId: state => state.receipt._id,
  getOriginalReceiptId: state => state.receipt.receipt_original_id,
  name: state => state.receipt.receipt_name,
  heroImage: state => state.receipt.receipt_heroImage,
  cookTime: state => state.receipt.receipt_cookTime,
  prepTime: state => state.receipt.receipt_prepTime,
  receiptType: state => state.receipt.receipt_type,
  macros: state => state.receipt.receipt_macros,
  ingredients: state => state.receipt.receipt_ingredients,
  steps: state => state.receipt.receipt_steps,
  nutrients: state => {
    const ingredients = state.receipt.receipt_ingredients;
    return ingredients.reduce((prev, current) => {
      const nutrientsIngredient = current.nutrients;
      for (const iterator in nutrientsIngredient) {
        prev[iterator] = nutrientsIngredient[iterator] + (prev[iterator] || 0);
      }
      return prev;
    }, {});
  }
};

const actions = {
  async loadReceipt({ commit, rootGetters }, receipt_id) {
    const menu_id = rootGetters['menuStore/getMenuId'];
    const receipt = await MenuRepository.menuReceipt(menu_id, receipt_id);
    commit('setReceipt', receipt);
  }
};
const mutations = {
  setReceipt(state, receipt) {
    state.receipt = receipt;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
