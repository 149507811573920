<template lang="pug">
.receipt-wrapper
  modalReceiptNavHeader(
    v-on:buttonCloseReceipt="buttonCloseReceipt",
    v-on:addReceiptToFavorites="addReceiptToFavorites",
    v-on:pullReceiptFromFavorites="pullReceiptFromFavorites",
    v-on:setCurrentTab="setCurrentTab",
    :isFavoriteReceipt="isFavoriteReceipt",
    :currentTab="currentTab"
  )
  modalImageTitle(
    :heroImage="heroImage",
    :receiptName="name",
    :receiptType="receipt_type",
    :prepTime="prepTime",
    :cookTime="cookTime"
  )
  component(:is="currentTabComponent") 
</template>

<script>
import { mdiClose, mdiHeart, mdiHeartOutline, mdiDotsVertical } from "@mdi/js";
import {
  defineAsyncComponent,
  inject,
  toRefs,
  computed,
  provide,
  ref,
} from "vue";
import { useStore } from "vuex";

import modalReceiptNavHeader from "@/components/modal-receipt/card-sections/nav-header.vue";
import modalImageTitle from "@/components/modal-receipt/card-sections/image-title.vue";

import { identificationReceipt } from "@/app/composables/identificationReceipt";

import { UserRepository } from "@/app/repository/user.repository";

const macrosComponentAsync = defineAsyncComponent(() =>
  import("../components/macros/macros-component.vue")
);
const ingredientsComponentAsync = defineAsyncComponent(() =>
  import("../components/ingredients-component.vue")
);
const receiptComponentAsync = defineAsyncComponent(() =>
  import("../components/steps-component.vue")
);
const nutritionComponentAsync = defineAsyncComponent(() =>
  import("../components/nutrition-component.vue")
);

export default {
  name: "custom-receipt-wrapper",
  components: {
    modalReceiptNavHeader,
    modalImageTitle,
    macrosComponentAsync,
    ingredientsComponentAsync,
    receiptComponentAsync,
    nutritionComponentAsync,
  },
  props: {
    receipt_id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    mdiClose,
    mdiHeart,
    mdiHeartOutline,
    mdiDotsVertical,
  }),
  setup(props) {
    const buttonCloseReceipt = inject("buttonCloseReceipt");
    const store = useStore();
    const { receipt_id } = toRefs(props);
    const currentTab = ref("macros");

    const loadReceipt = () => {
      store.dispatch("menuReceiptStore/loadReceipt", receipt_id.value);
    };
    loadReceipt();

    provide("reloadReceipt", loadReceipt);

    const heroImage = computed(
      () => store.getters["menuReceiptStore/heroImage"] || null
    );
    const cookTime = computed(
      () => store.getters["menuReceiptStore/cookTime"] + "" || null
    );
    const prepTime = computed(
      () => store.getters["menuReceiptStore/prepTime"] + "" || null
    );

    const receipt_type = computed(() => {
      const { receipt_type } = identificationReceipt(
        store.getters["menuReceiptStore/receiptType"]
      );
      return receipt_type.value;
    });

    const name = computed(() => store.getters["menuReceiptStore/name"]);

    const receiptOriginalID = computed(
      () => store.getters["menuReceiptStore/getOriginalReceiptId"]
    );

    const addReceiptToFavorites = async () => {
      await UserRepository.addReceiptToFavorites(receiptOriginalID.value);
      await store.dispatch("userStore/getMe");
    };
    const pullReceiptFromFavorites = async () => {
      await UserRepository.removeReceiptToFavorites(receiptOriginalID.value);
      await store.dispatch("userStore/getMe");
    };

    const isFavoriteReceipt = computed(() => {
      const favorites = store.getters["userStore/getReceiptsFavorites"];
      console.log(favorites);
      return !!favorites.find((f) => f == receiptOriginalID.value);
    });

    const currentTabComponent = computed(() => {
      return currentTab.value + "ComponentAsync";
    });

    const setCurrentTab = (tabName) => {
      currentTab.value = tabName;
    };

    return {
      buttonCloseReceipt,
      heroImage,
      cookTime,
      prepTime,
      receipt_type,
      name,
      addReceiptToFavorites,
      pullReceiptFromFavorites,
      isFavoriteReceipt,
      currentTabComponent,
      setCurrentTab,
      currentTab,
    };
  },
};
</script>
<style lang="scss" scoped>
.receipt-wrapper {
  max-height: 860px;
  @apply border w-full max-w-2xl bg-white rounded-lg shadow-2xl overflow-y-auto;
}
</style>