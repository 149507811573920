import { createStore } from 'vuex';
import ingredientsV2Store from './wizard/ingredientsv2.store';
import analysisStore from './analysis/analysis.store';
import plansSubscriptionsStore from './plansSubscriptions/plans.subscriptions.store';
import userStore from './user/user.store';
import authStore from './user/auth.store';
import userMenusStore from './user/user.menus.store';
import calendarStore from './calendar/calendar.store';
import menuStore from './menu/menu.store';
import menuReceiptStore from './menu/menu.receipt.store';
export default createStore({
  modules: {
    ingredientsV2Store,
    analysisStore,
    plansSubscriptionsStore,
    authStore,
    userStore,
    userMenusStore,
    menuStore,
    menuReceiptStore,
    calendarStore
  }
});
