<template lang="pug">
.hero
  .container.h-full
    .content-hero
      .grid.grid-cols-2.items-center.gap-8
        .col-1
          h1.title Pierde peso facilmente con la <span>dieta Keto</span>
          p Mejora la calidad de tus dietas con las mejores recetas saludables.
          .flex.mt-6
            router-link(:to="{ name: 'Quiz' }")
              ctaButton(textButton="Quiero perder peso!") 
          .sub-text
            p Descubre todo lo que podemos hacer por ti! Nuestros planes de dietas basadas en recetas Keto no te dejaran indiferente, prueba una y no querras volver a la comida basura nunca más!
        .col-2.relative
          .dots.dot-1
          .dots.dot-2
          .block-image-0
            img(
              src="https://images.pexels.com/photos/842142/pexels-photo-842142.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            )
          .centered-image
            img(src="@/assets/receipt_1.png")
          .block-image-1
            img(
              src="https://images.pexels.com/photos/5895770/pexels-photo-5895770.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
            )
</template>



<script>
export default {
  components: {},
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.hero {
  min-height: calc(100vh - 90px);
  @apply h-full max-h-screen flex items-center;
}
.content-hero {
  .col-1 {
    h1 {
      @apply -mt-12 pb-4;
    }
    p {
      @apply text-lg font-medium;
    }

    .sub-text {
      max-width: 75%;
      p {
        @apply text-sm font-normal mt-6;
      }
    }
  }
}

.centered-image {
  @apply flex justify-center z-10 relative;
  img {
    width: 380px;
    @apply rounded-2xl overflow-hidden shadow-xl;
  }
}

.block-image-1 {
  @apply absolute -ml-8 -mt-16 z-20;
  img {
    width: 220px;
    @apply rounded-2xl overflow-hidden shadow-xl;
  }
}

.block-image-0 {
  @apply absolute -mr-0 -mt-20 right-0;
  img {
    width: 280px;
    @apply rounded-2xl overflow-hidden shadow-xl;
  }
}

.dots {
  background-image: url("~@/assets/dots.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 192px;
  height: 146px;
  position: absolute;
  z-index: -1;
}

.dot-1 {
  @apply -mt-24;
}
.dot-2 {
  @apply bottom-0 right-0 -mb-14 mr-12;
}
</style>
