<template lang="pug">
.background
Hero
Section1
Section2
Section3
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/home/hero";
import Section1 from "@/components/home/section1";
import Section2 from "@/components/home/section2";
import Section3 from "@/components/home/section3";

export default {
  name: "Home",
  components: {
    Hero,
    Section1,
    Section2,
    Section3,
  },
};
</script>

<style scoped lang="scss">
</style>
