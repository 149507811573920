import { roundToTwoDecimal } from '../services/transformMetrics';

export interface SubscriptionPlanImpl {
  _id: string;
  price: number;
  currency: string;
  sign: string;
  billing_months: number;
}

export class SubscriptionPlanEntity implements SubscriptionPlanImpl {
  _id: string;
  price: number;
  currency: string;
  sign: string;
  billing_months: number;

  constructor(partial: SubscriptionPlanImpl) {
    this._id = partial._id;
    this.price = partial.price;
    this.currency = partial.currency;
    this.sign = partial.sign;
    this.billing_months = partial.billing_months;
  }

  priceWeek() {
    return this.sign + roundToTwoDecimal(this.priceFormated() / this.billing_months / 4).toFixed(2);
  }

  priceFormated() {
    return this.price / 100;
  }

  priceFormatedWithMoney() {
    return this.currency + this.priceFormated();
  }
}
