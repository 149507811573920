import { computed } from 'vue';

type receiptTypes = 'break_fast' | 'lunch' | 'dinner' | 'snack';

const receipt_types: Record<receiptTypes, string> = {
  break_fast: 'Desayuno',
  lunch: 'Almuerzo',
  dinner: 'Comida',
  snack: 'Snack/Cena'
};

export function identificationReceipt(receiptType: receiptTypes) {
  const receipt_type = computed(() => receipt_types[receiptType]);

  return { receipt_type };
}
