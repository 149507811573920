import userService from '@/app/services/users/user.service';

const state = () => ({
  user_id: null,
  user_active: false,
  user_email: null,
  user_language: null,
  user_name: null,
  user_post_name: null,
  favorites_receipts: []
});

const getters = {
  getUserID: state => state.user_id,
  getReceiptsFavorites: state => state.favorites_receipts
};

const actions = {
  async createAccountUser({ commit, state, rootGetters }, user_email) {
    const data_metrics = rootGetters['analysisStore/getDataWizard'];
    const data_user = {
      email: user_email,
      lang: 'es'
    };
    const ingredients_to_exclude = rootGetters['ingredientsV2Store/getIngredientsForm'];
    return await userService.createAccount({ data_metrics, data_user, excluded_ingredients: ingredients_to_exclude });
  },
  updateUserData({ commit }, userData) {
    commit('setUserData', userData);
  },

  updateUserID({ commit }, user_id) {
    commit('setUserData', {
      _id: user_id
    });
  },

  async getMe({ commit, dispatch }) {
    const user = await userService.getMe();
    if (!user || !user.user_active) {
      return commit('authStore/logout', null, { root: true });
    }
    commit('setUserData', user);
  }
};

const mutations = {
  setUserData(state, userData) {
    state.user_id = userData._id;
    state.user_active = userData.user_active ?? state.user_active;
    state.user_email = userData.user_email ?? state.user_email;
    state.user_language = userData.user_language ?? state.user_language;
    state.user_name = userData.user_name ?? state.user_name;
    state.user_post_name = userData.user_post_name ?? state.user_post_name;
    state.favorites_receipts = userData.favorites_receipts ?? state.favorites_receipts;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
